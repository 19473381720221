import styled from "styled-components";

import SectionLabel from "components/atoms/section/Label";
import SectionTitle from "components/atoms/section/Title";
import SectionDesc from "components/atoms/section/Desc";

import reports from "datas/reports";

const DESC =
  "리서치 목적, 참가자 개요, 피드백 분석 자료, 항목별 종합 분석 결과, 개선된 UX/UI 등 \n A-Z까지 서비스 맞춤형 UX 리서치 결과 리포트를 받으실 수 있습니다";
const MOBILE_DESC =
  "리서치 목적, 참가자 개요, 피드백 분석 자료, \n 항목별 종합 분석 결과, 개선된 UX/UI 등 A-Z까지 서비스 맞춤형 \n UX 리서치 결과 리포트를 받으실 수 있습니다";

const Report = () => {
  return (
    <Section>
      <SectionLabel>Report</SectionLabel>
      <SectionTitle>
        리서치 후 한눈에 정리된 <br />
        리포트를 받아보세요!
      </SectionTitle>
      <Desc>{DESC}</Desc>
      <MobileDesc>{MOBILE_DESC}</MobileDesc>
      <Content>
        {reports.map(({ id, image }) => (
          <Item key={id}>
            <img src={image} alt="" />
          </Item>
        ))}
      </Content>
    </Section>
  );
};

const Section = styled.section`
  padding: 200px 100px;
  text-align: center;
  background: #f8f9fc;
  @media screen and (max-width: 1000px) {
    padding: 80px 28px;
  }
`;
const Desc = styled(SectionDesc)`
  white-space: pre-line;
  @media screen and (max-width: 860px) {
    white-space: normal;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
const MobileDesc = styled(Desc)`
  display: none;
  white-space: pre-line;
  @media screen and (max-width: 600px) {
    display: block;
  }
`;

const Content = styled.ul`
  margin: 100px auto 0;
  max-width: 1278px;
  @media screen and (max-width: 600px) {
    margin-top: 56px;
  }
`;

const Item = styled.li`
  text-align: right;

  &:not(:last-child) {
    margin-bottom: 280px;

    img {
      max-width: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    &:not(:last-child) {
      margin-bottom: 60px;
    }
  }
`;

export default Report;
