import { useState } from "react";
import styled from "styled-components";

import SectionLabel from "components/atoms/section/Label";
import SectionTitle from "components/atoms/section/Title";
import SECTION_WIDTH from "components/atoms/section/Width";
import COLORS from "components/atoms/colors";

import processes from "datas/process";
import useBackgroundAnimation from "hooks/useBackgroundAnimation";

const backgroundColor = { defaultColor: "#111", chageColor: "#fff" };

const Process = () => {
  const [index, setIndex] = useState(1);
  const { sectionRef, contentRef } = useBackgroundAnimation(backgroundColor);

  return (
    <Section id="process" ref={sectionRef}>
      <Inner ref={contentRef}>
        <SectionLabel>Process</SectionLabel>
        <SectionTitle>
          UX 리서치 <br />
          이렇게 진행해요!
        </SectionTitle>

        <TabWrpper>
          {processes.map(({ id }) => (
            <li key={id} onClick={() => setIndex(id)}>
              <StepText isSelected={index === id}>
                STEP<span>.</span>
              </StepText>
              <StepNum isSelected={index === id}>{id}</StepNum>
            </li>
          ))}
        </TabWrpper>
        <StepWrap>
          {processes.map(
            ({ id, image, title, description }) =>
              index === id && (
                <StepItem key={id} background={image}>
                  <ItemInner>
                    <Title>
                      {id}. {title}
                    </Title>
                    <Desc>{description}</Desc>
                  </ItemInner>
                </StepItem>
              )
          )}
        </StepWrap>
      </Inner>
    </Section>
  );
};

const Section = styled.section`
  background-color: #111;
  transition: background-color 1.5s ease;
`;

const Inner = styled.div`
  max-width: ${SECTION_WIDTH + 200}px;
  padding: 200px 100px;
  margin: 0 auto;
  text-align: center;

  opacity: 0;
  transition: opacity 2s ease;

  @media screen and (max-width: 900px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media screen and (max-width: 600px) {
    padding: 80px 24px;
  }
`;

const TabWrpper = styled.ul`
  max-width: 648px;
  margin: 56px auto 40px;
  display: flex;
  justify-content: center;
  gap: 24px;

  @media screen and (max-width: 767px) {
    gap: 13px;
  }
  @media screen and (max-width: 600px) {
    margin: 32px auto 20px;
  }
`;

const StepText = styled.p`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: ${({ isSelected }) => (isSelected ? "#111" : "#00000000")};

  span {
    display: ${({ isSelected }) => (isSelected ? "inline-block" : "none")};
    color: ${COLORS.main};
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 12px;
  }
`;

const StepNum = styled.span`
  display: block;
  width: 72px;
  height: 72px;
  line-height: 72px;
  border-radius: 100%;
  background: ${({ isSelected }) => (isSelected ? "#000" : "#f3f3f5")};
  font-weight: 700;
  font-size: 28px;
  letter-spacing: -0.03em;
  color: ${({ isSelected }) => (isSelected ? COLORS.main : "#717171")};
  cursor: pointer;

  transition: background-color 0.5s, color 0.5s;
  :hover {
    background-color: #48f30c;
    color: #000;
  }

  @media screen and (max-width: 600px) {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 12px;
  }
`;

const StepWrap = styled.ul``;

const StepItem = styled.li`
  height: 600px;
  background: url(${({ background }) => background}) no-repeat center;
  background-size: cover;
  border-radius: 40px;

  @media screen and (max-width: 600px) {
    height: 204px;
    border-radius: 20px;
  }
`;

const ItemInner = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 40px;
  text-align: left;
  padding: 224px 80px;

  @media screen and (max-width: 600px) {
    padding: 64px 25.5px;
    border-radius: 20px;
  }
`;

const Title = styled.p`
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.03em;
  color: #ffffff;

  @media screen and (max-width: 600px) {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 20px;
  }
`;

const Desc = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: -0.02em;
  color: #ffffff;

  @media screen and (max-width: 600px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
`;

export default Process;
