import Contact from "components/templates/Contact";
import Data from "components/templates/Data";
import Diagnosis from "components/templates/Diagnosis";
import Fee from "components/templates/Fee";
import Footer from "components/templates/Footer";
import Header from "components/organisms/Header";
import Introduce from "components/templates/Introduce";
import Leolap from "components/templates/Leolap";
import Partner from "components/templates/Partner";
import Process from "components/templates/Process";
import Report from "components/templates/Report";
import Skill from "components/templates/Skill";
import Visual from "components/templates/Visual";
import Why from "components/templates/Why";

const Landing = () => {
  return (
    <>
      <Header />
      <Visual />
      <Introduce />
      <Why />
      <Skill />
      <Process />
      <Data />
      <Report />
      <Diagnosis />
      <Leolap />
      <Fee />
      <Partner />
      <Contact />
      <Footer />
    </>
  );
};

export default Landing;
