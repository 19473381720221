import rawIconChart from "assets/images/data/raw_icon_chart.svg";
import rawIconInterview from "assets/images/data/raw_icon_interview.svg";
import rawIconPhoto from "assets/images/data/raw_icon_photo.svg";
import rawIconPieChart from "assets/images/data/raw_icon_pie_chart.svg";
import rawIconVideo from "assets/images/data/raw_icon_video.svg";
import rawIconVoice from "assets/images/data/raw_icon_voice.svg";

import resultIconReport from "assets/images/data/result_icon_report.svg";
import resultIconUxUi from "assets/images/data/result_icon_ux_ui.svg";

const researchDatas = {
  raws: [
    {
      id: 1,
      text: (
        <>
          인터뷰의 <br />
          정보 및 분석 자료
        </>
      ),
      icon: rawIconInterview,
    },
    {
      id: 2,
      text: (
        <>
          영상 녹화 및 <br />
          테스트 화면 녹화
        </>
      ),
      icon: rawIconVideo,
    },
    { id: 3, text: "음성 녹음 파일", icon: rawIconVoice },
    { id: 4, text: "인터뷰 사진 자료", icon: rawIconPhoto },
    { id: 5, text: "설문지 결과 자료", icon: rawIconChart },
    {
      id: 6,
      text: (
        <>
          설문 수치 <br />
          분석 파일
        </>
      ),
      icon: rawIconPieChart,
    },
  ],
  results: [
    {
      id: 1,
      text: (
        <>
          정성·정량 피드백 <br />
          분석자료
        </>
      ),
      icon: resultIconReport,
    },
    {
      id: 2,
      text: (
        <>
          UX/UI <br />
          개선 방향 제안
        </>
      ),
      icon: resultIconUxUi,
    },
  ],
};

export default researchDatas;
