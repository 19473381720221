import processImg1 from "assets/images/process/process_img1.png";
import processImg2 from "assets/images/process/process_img2.png";
import processImg3 from "assets/images/process/process_img3.png";
import processImg4 from "assets/images/process/process_img4.png";
import processImg5 from "assets/images/process/process_img5.png";
import processImg6 from "assets/images/process/process_img6.png";
import processImg7 from "assets/images/process/process_img7.png";

const processes = [
  {
    id: 1,
    title: "리서치 기획",
    description: (
      <>
        서비스 성격과 리서치 목적에 따른 <br />
        적합한 조사 진행 방식을 함께 조율합니다
      </>
    ),
    image: processImg1,
  },
  {
    id: 2,
    title: "사용자 리쿠르팅",
    description: (
      <>
        리서치 목적에 맞는 검증된 대상자를 <br />
        리쿠르팅합니다
      </>
    ),
    image: processImg2,
  },
  {
    id: 3,
    title: "태스크 ･ 시나리오 설계",
    description: (
      <>
        리서치가 효과적으로 진행될 수 있도록 <br />
        검증하고 싶은 가설을 설정하고 조율합니다
      </>
    ),
    image: processImg3,
  },
  {
    id: 4,
    title: "테스트 ･ 인터뷰 진행",
    description: (
      <>
        설계한 기획을 바탕으로 모더레이터의 <br />
        지휘하에 테스트를 진행합니다
      </>
    ),
    image: processImg4,
  },
  {
    id: 5,
    title: "데이터 분석  ･  솔루션 제안",
    description: (
      <>
        유저 피드백과 테스트 결과를 정리하고 정성, <br />
        정량적으로 분석하여 개선 방향을 도출합니다
      </>
    ),
    image: processImg5,
  },
  {
    id: 6,
    title: "결과 리포트 도출",
    description: (
      <>
        리서치 개요, 참가자 특성, 피드백 분석과 <br />
        개선점 도출까지 테스트･인터뷰 과정을 분석한 <br />
        정성･정량 데이터 분석 결과 보고서를 제공합니다
      </>
    ),
    image: processImg6,
  },
  {
    id: 7,
    title: "UX/UI 개선 방안 제안",
    description: (
      <>
        리서치를 통해 얻은 인사이트를 적용하여 <br />
        UX/UI를 개선 방안을 제안드립니다
      </>
    ),
    image: processImg7,
  },
];

export default processes;
