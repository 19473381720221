import styled from "styled-components";
import { createPortal } from "react-dom";

import menus from "datas/menus";
import iconClose from "assets/images/icon_close.svg";

const MobileMenu = ({ onToggle }) => {
  return (
    <>
      {createPortal(
        <Container>
          <BtnClose onClick={onToggle}>
            <img src={iconClose} alt="" />
          </BtnClose>
          <ul>
            {menus.map(({ id, menu }) => (
              <MenuItem key={id}>
                <a href={`#${id}`} onClick={onToggle}>
                  {menu}
                </a>
              </MenuItem>
            ))}
          </ul>
        </Container>,
        document.body
      )}
    </>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: #111;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const BtnClose = styled.button`
  position: absolute;
  top: 20px;
  right: 26px;
`;

const MenuItem = styled.li`
  margin-bottom: 50px;
  list-style: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
`;

export default MobileMenu;
