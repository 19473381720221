import styled from "styled-components";

import COLORS from "components/atoms/colors";

import logoLeolap from "assets/images/leolap/logo_leolap.svg";
import { ReactComponent as ImgArrow } from "assets/images/leolap/arrow.svg";
import { ReactComponent as ImgArrowM } from "assets/images/leolap/m_arrow.svg";
import backgroundImg from "assets/images/leolap/background.png";

const TITLE = (
  <>
    UX/UI 후속 연계 과정은
    <br />
    서비스 제너레이터 <img src={logoLeolap} alt="" /> 과 함께합니다
  </>
);
const MOBILE_TITLE = (
  <>
    UX/UI 후속 연계 과정은
    <br />
    서비스 제너레이터
    <br />
    <img src={logoLeolap} alt="" /> 과 함께합니다
  </>
);
const DESC =
  "모디파인는 국내 대기업, 스타트업과 함께 100개 이상의 프로젝트를 수행한 \n 서비스 빌더 디자인 에이전시 ‘리오랩 LEOLAP’ 과 함께합니다";
const MOBILE_DESC =
  "모디파인는 국내 대기업, 스타트업과 함께 100개 이상의 \n 프로젝트를 수행한 서비스 빌더 디자인 에이전시 \n ‘리오랩 LEOLAP’ 과 함께합니다";

const Leolap = () => {
  return (
    <Section>
      <Title>{TITLE}</Title>
      <MobileTitle>{MOBILE_TITLE}</MobileTitle>
      <Desc>{DESC}</Desc>
      <MobileDesc>{MOBILE_DESC}</MobileDesc>
      <BtnLink href="https://leolap.com" target="_blank">
        리오랩 알아보기
        <ImgArrow className="web" />
        <ImgArrowM className="mob" />
      </BtnLink>
    </Section>
  );
};

const Section = styled.section`
  padding: 360px 0;
  text-align: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(${backgroundImg});
  background-size: cover;
  @media screen and (max-width: 600px) {
    padding: 144px 0;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 121%;
  letter-spacing: -0.04em;
  color: #ffffff;
  img {
    margin: 0 5px 0 10px;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
const MobileTitle = styled(Title)`
  display: none;
  font-size: 20px;
  line-height: 28px;
  img {
    width: 92px;
    height: 15px;
  }
  @media screen and (max-width: 600px) {
    display: block;
  }
`;

const Desc = styled.p`
  margin-top: 40px;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: -0.02em;
  color: #ffffff;
  white-space: pre-line;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
const MobileDesc = styled(Desc)`
  display: none;
  margin-top: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  @media screen and (max-width: 600px) {
    display: block;
  }
`;

const BtnLink = styled.a`
  display: inline-flex;
  align-items: center;
  margin-top: 74px;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: ${COLORS.main};
  transition: color 0.5s;

  .web {
    margin-top: 4px;
    margin-left: 14px;
  }
  .mob {
    display: none;
  }

  :hover {
    color: #fff;
    path {
      transition: stroke 0.5s;
      stroke: #fff;
    }
  }
  @media screen and (max-width: 600px) {
    margin-top: 40px;
    font-size: 14px;
    line-height: 20px;
    .web {
      display: none;
    }
    .mob {
      margin-left: 4px;
      margin-top: 1px;
      display: inline-block;
    }
  }
`;

export default Leolap;
