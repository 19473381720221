import styled from "styled-components";

import SECTION_WIDTH from "components/atoms/section/Width";
import COLORS from "components/atoms/colors";

import logo from "assets/images/footer_logo.svg";

const Footer = () => {
  return (
    <Section>
      <Inner>
        <LogoWrap>
          <img src={logo} alt="modefyin" />
        </LogoWrap>
        <Title>
          email
          <span />
        </Title>
        <Content>modefyin@leolap.com</Content>
        <Title>
          space
          <span />
        </Title>
        <Content>서울특별시 강남구 테헤란로78길 14-6, 4층</Content>
        <InfoList>
          <InfoContent>사업자등록번호 : 798-88-02103</InfoContent>
          <InfoContent>대표자 : 허재혁</InfoContent>
          <InfoContent>산업디자인전문회사 제 12641호</InfoContent>
        </InfoList>
        <CopyRight>© 2022, modefyin. All right reserved.</CopyRight>
      </Inner>
    </Section>
  );
};

const Section = styled.section`
  background: #0e0e0e;
  padding: 80px;
  color: #fff;
  @media screen and (max-width: 600px) {
    padding: 40px 24px 60px;
  }
`;

const Inner = styled.div`
  max-width: ${SECTION_WIDTH}px;
  margin: 0 auto;
`;

const LogoWrap = styled.h1`
  margin-bottom: 54px;
  @media screen and (max-width: 600px) {
    margin-bottom: 36px;
  }
`;

const Title = styled.h2`
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.6);

  span {
    display: inline-block;
    margin-left: 3px;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: ${COLORS.main};
  }
  @media screen and (max-width: 600px) {
    margin: 0;
    font-size: 11px;
    line-height: 16px;
    span {
      margin-left: 1px;
      width: 3px;
      height: 3px;
    }
  }
`;

const Content = styled.p`
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: -0.01em;
  @media screen and (max-width: 600px) {
    font-size: 10.5px;
    margin-bottom: 12px;
  }
`;

const InfoList = styled.ul`
  margin-top: 48px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 660px) {
    margin-top: 36px;
    display: block;
  }
`;

const InfoContent = styled.li`
  font-size: 14px;
  line-height: 100%;
  color: rgba(255, 255, 255, 0.5);

  &::after {
    display: inline-block;
    content: "";
    width: 1px;
    height: 13px;
    background: rgba(255, 255, 255, 0.5);
    margin: 0 8px;
  }

  &:last-child::after {
    display: none;
  }
  @media screen and (max-width: 660px) {
    line-height: 20px;
    &::after {
      display: none;
    }
  }
  @media screen and (max-width: 600px) {
    font-size: 10px;
    letter-spacing: -0.01em;
  }
`;

const CopyRight = styled.p`
  margin-top: 20px;
  font-size: 14px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.5);
  @media screen and (max-width: 600px) {
    margin-top: 12px;
    font-size: 10px;
  }
`;

export default Footer;
