import styled from "styled-components";
import COLORS from "components/atoms/colors";

const SectionLabel = styled.span`
  margin-bottom: 60px;
  display: inline-block;
  text-align: center;
  min-width: 120px;
  padding: 20px 32px;
  background: ${COLORS.main};
  border-radius: 62.5px;

  font-family: "Gotham";
  letter-spacing: -0.01em;
  font-weight: 700;
  font-size: 24px;
  color: #111;
  line-height: 100%;

  @media screen and (max-width: 600px) {
    margin-bottom: 40px;
    min-width: auto;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 12px;
  }
`;

export default SectionLabel;
