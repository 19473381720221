import styled, { keyframes } from "styled-components";

import SECTION_WIDTH from "components/atoms/section/Width";

import partner1 from "assets/images/partner/partner1.png";
import partner2 from "assets/images/partner/partner2.png";
import partner1M from "assets/images/partner/m_partner1.png";
import partner2M from "assets/images/partner/m_partner2.png";

const Partner = () => {
  return (
    <Section>
      <TitleView>
        <Title>Partner.</Title>
        <SubTitle>
          우리와 함께하는 <br />
          파트너들입니다
        </SubTitle>
      </TitleView>
      <Content>
        <FlowTop isSmall>
          <img src={partner1} className="web" alt="" />
          <img src={partner1} className="web" alt="" />
          <img src={partner1M} className="mob" alt="" />
          <img src={partner1M} className="mob" alt="" />
        </FlowTop>
        <FlowBottom>
          <img src={partner2} className="web" alt="" />
          <img src={partner2} className="web" alt="" />
          <img src={partner2M} className="mob" alt="" />
          <img src={partner2M} className="mob" alt="" />
        </FlowBottom>
      </Content>
    </Section>
  );
};

const Section = styled.section`
  padding: 200px 0;
  background: #000;
  color: #fff;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    padding: 200px 0 154px;
  }
  @media screen and (max-width: 600px) {
    padding: 80px 0;
  }
`;

const TitleView = styled.div`
  max-width: ${SECTION_WIDTH}px;
  margin: 0 auto;
  padding-left: 80px;
  @media screen and (max-width: 600px) {
    padding-left: 24px;
  }
`;

const Title = styled.h1`
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

const SubTitle = styled.h2`
  font-weight: 700;
  font-size: 40px;
  line-height: 170%;
  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Content = styled.div`
  position: relative;
  margin-top: 100px;

  &::before,
  &::after {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 229px;
    height: 229px;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    transform: matrix(0, 1, 1, 0, 0, 0);
  }

  &::after {
    left: auto;
    right: 0;
    transform: rotate(90deg);
  }
  @media screen and (max-width: 900px) {
    &::before,
    &::after {
      display: none;
    }
  }
  @media screen and (max-width: 600px) {
    margin-top: 32px;
  }
`;

const FlowWrap = styled.div`
  margin-top: 50px;
  text-align: center;
  display: flex;

  img {
    max-width: none;
  }

  & img + img {
    margin-left: 90px;
  }
  .mob {
    display: none;
  }

  @media screen and (max-width: 600px) {
    margin-top: 20px;
    & img + img {
      margin-left: 50px;
    }
    .web {
      display: none;
    }
    .mob {
      display: inline-block;
    }
  }
`;

const flow = keyframes`
  from {
    transform: translateX(calc(-1733px - 90px));
  }
  to {
    transform: translateX(0);
  }
`;
const flowM = keyframes`
  from {
    transform: translateX(calc(-729px - 50px));
  }
  to {
    transform: translateX(0);
  }
`;

const FlowTop = styled(FlowWrap)`
  position: relative;
  left: 0;
  animation: ${flow} 25s linear infinite;
  @media screen and (max-width: 600px) {
    animation: ${flowM} 20s linear infinite;
  }
`;

const flowReverse = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-2044px - 90px));
  }
`;
const flowReverseM = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-860px - 50px));
  }
`;

const FlowBottom = styled(FlowWrap)`
  position: relative;
  left: 0;
  animation: ${flowReverse} 25s linear infinite;
  @media screen and (max-width: 600px) {
    animation: ${flowReverseM} 20s linear infinite;
  }
`;

export default Partner;
