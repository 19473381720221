import { useState } from "react";
import styled from "styled-components";

import arrowRight from "assets/images/arrow_right.svg";
import bg from "assets/images/fee/bg.png";
import bgBig from "assets/images/fee/bgBig.png";
import bgMobile from "assets/images/fee/bgMobile.png";
import { DATAS } from "datas/fees";
import Button from "components/atoms/button";

const Fee = () => {
  const [index, setIndex] = useState(0);
  return (
    <Section id="fee">
      <SectionTitle>가격 패키지</SectionTitle>
      <SectionDesc>
        합리적인 가격으로 목적에 맞는 패키지를 이용해 보세요
        <span>
          * 서비스의 상황에 따라 세부 패키지 구성은 변경될 수 있습니다
        </span>
      </SectionDesc>
      <Prices>
        {DATAS.map(({ title, subTitle }, i) => (
          <PriceTab isActive={i === index} onClick={() => setIndex(i)}>
            <p>{title}</p>
            <span>{subTitle}</span>
          </PriceTab>
        ))}
      </Prices>
      {DATAS.map(({ title, subTitle, content, price, left, right }, i) => (
        <ContentWrap isActive={i === index}>
          <Header>
            <Title>
              <p>{title}</p>
              <span>{subTitle}</span>
            </Title>
            <TitlePrice>
              <p>{price}</p>
              <span className="mobile">(VAT 별도)</span>
            </TitlePrice>
          </Header>
          <SubTitle>
            {content}
            <div>(VAT 별도)</div>
          </SubTitle>
          <Items>
            <Left>
              {left.map(({ title, subTitle, content }) => (
                <div>
                  <li>
                    {title}
                    <span>{subTitle}</span>
                  </li>
                  <SubItem gray={i === 0}>
                    {content?.map((v, i) => (
                      <li>{v}</li>
                    ))}
                  </SubItem>
                </div>
              ))}
            </Left>
            <Right>
              {right.map(({ title }) => (
                <li>{title}</li>
              ))}
            </Right>
          </Items>
        </ContentWrap>
      ))}
      <BtnMore
        href="https://leolap.notion.site/7ca836ca2caa4da49597a10933953874"
        target="_blank"
      >
        자세히 알아보기
        <img src={arrowRight} alt="" />
      </BtnMore>
    </Section>
  );
};

const Section = styled.section`
  padding: 200px 28px;
  background: #111;
  text-align: center;
  color: #fff;
  overflow: hidden;
  @media screen and (max-width: 600px) {
    padding: 80px 28px;
  }
`;

const SectionTitle = styled.h1`
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
  letter-spacing: -0.05em;
  @media screen and (max-width: 600px) {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
  }
`;

const SectionDesc = styled.p`
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.02em;

  span {
    margin-top: 20px;
    display: block;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);
  }
  @media screen and (max-width: 830px) {
    margin-bottom: 60px;
  }
  @media screen and (max-width: 600px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    span {
      margin-top: 12px;
      font-size: 10px;
      line-height: 16px;
    }
  }
`;

const Prices = styled.section`
  display: flex;
  justify-content: center;
  gap: 31px;
  margin: 60px 0 32px;
  @media screen and (max-width: 1170px) {
    gap: 15px;
    margin: 30px 0 15px;
  }
  @media screen and (max-width: 830px) {
    display: none;
  }
`;

const PriceTab = styled.article`
  width: 350px;
  height: 92px;
  background: #111111;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border: ${({ isActive }) =>
    isActive
      ? "1px solid rgba(255, 255, 255, 0.4)"
      : "1px solid rgba(255, 255, 255, 0.15)"};
  background: ${({ isActive }) => isActive && `url(${bg})`};
  color: ${({ isActive }) => (isActive ? "#ffffff" : "#424345")};
  p {
    font-family: "Gotham Black";
    font-weight: 900;
    font-size: 28px;
    line-height: 28px;
  }
  span {
    font-family: "Gotham";
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }
  @media screen and (max-width: 1170px) {
    width: 250px;
    height: 80px;
    p {
      font-size: 24px;
    }
  }
`;

const ContentWrap = styled.section`
  padding: 52px;
  width: 1112px;
  height: 492px;
  background: url(${bgBig});
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  margin: 0 auto;
  display: ${({ isActive }) => (isActive ? "block" : "none")};
  @media screen and (max-width: 1170px) {
    width: 780px;
    padding: 30px;
    height: 430px;
  }
  @media screen and (max-width: 830px) {
    background: url(${bgMobile});
    background-size: cover;
    display: block;
    margin-top: 32px;
    width: 303px;
    height: fit-content;
    padding: 36px 24px;
  }
`;

const Header = styled.article`
  display: flex;
  font-family: "Gotham Black";
  font-weight: 900;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 12px;
  span {
    margin-left: 12px;
    font-family: "Gotham";
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
  }
  @media screen and (max-width: 1170px) {
    font-size: 32px;
    line-height: 35px;
    span {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 830px) {
    display: block;
    text-align: left;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 32px;
    span {
      letter-spacing: -0.05em;
      font-size: 14px;
      line-height: 16px;
      margin-left: 8px;
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  @media screen and (max-width: 830px) {
    margin-bottom: 16px;
  }
`;
const TitlePrice = styled(Title)`
  max-width: fit-content;
  .mobile {
    display: none;
  }
  @media screen and (max-width: 830px) {
    margin-bottom: 32px;
    align-items: flex-end;
    font-size: 26px;
    line-height: 26px;
    .mobile {
      display: block;
      font-family: "Pretendard";
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #a2a5aa;
      margin-left: 8px;
      margin-bottom: 3px;
    }
  }
`;
const SubTitle = styled.article`
  display: flex;
  font-family: "Noto Sans KR";
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #ffffff;
  align-items: center;
  margin-bottom: 52px;
  p {
    flex: 1;
    text-align: left;
  }
  span {
    color: #48f30c;
    text-decoration: underline;
    font-weight: 700;
  }
  div {
    font-size: 16px;
    line-height: 16px;
    color: #a2a5aa;
  }
  @media screen and (max-width: 1170px) {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 30px;
    div {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 830px) {
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 32px;
    div {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
`;

const Items = styled.article`
  display: flex;
  text-align: left;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #eceff2;
  .purple {
    color: #9f92ff;
  }
  @media screen and (max-width: 1170px) {
    font-size: 14px;
  }
  @media screen and (max-width: 830px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    display: block;
    text-indent: -18px;
    margin-left: 18px;
  }
`;
const Left = styled.div`
  flex: 1;
`;
const Right = styled.div`
  flex: 1;
`;
const SubItem = styled.div`
  color: ${({ gray }) => (gray ? "#a2a5aa" : "#9f92ff")};
  margin-left: 30px;
`;

const BtnMore = styled(Button)`
  transition: background-color 0.5s;
  :hover {
    background-color: #31a608;
  }
  margin-top: 120px;
  @media screen and (max-width: 1170px) {
    margin-top: 90px;
  }
  @media screen and (max-width: 830px) {
    margin-top: 60px;
  }
`;

export default Fee;
