import styled from "styled-components";

import SectionTitle from "components/atoms/section/Title";
import SectionDesc from "components/atoms/section/Desc";
import Button from "components/atoms/button";

import chartImg from "assets/images/diabnosis/chart.svg";
import arrowRight from "assets/images/arrow_right.svg";

const Diagnosis = () => {
  return (
    <Section id="diagnosis">
      <Title>
        UX 리서치, 고민 중이신가요? <br />
        먼저 간단한 진단을 받고 시작해 보세요!
      </Title>
      <Desc>
        UX/UI 전문가들이 휴리스틱 5가지 요소를 바탕으로 <br />
        현재 서비스가 어떤 상태인지 빠르게 진단해 드려요!
      </Desc>
      <ImgWrap>
        <img src={chartImg} alt="" />
      </ImgWrap>
      <BtnApply
        href="https://ifb2hl1sqfj.typeform.com/to/xEiYJKVb"
        target="_blank"
      >
        간단 진단 신청
        <img src={arrowRight} alt="" />
      </BtnApply>
    </Section>
  );
};

const Section = styled.section`
  padding: 200px 0;
  text-align: center;
  @media screen and (max-width: 600px) {
    padding: 80px 0;
  }
`;

const Title = styled(SectionTitle)`
  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 28px;
  }
`;
const Desc = styled(SectionDesc)`
  @media screen and (max-width: 600px) {
    margin-top: 20px;
    font-size: 12px;
    line-height: 20px;
  }
`;

const ImgWrap = styled.div`
  margin: 120px 0 140px 0;
  @media screen and (max-width: 600px) {
    margin: 60px 69px;
  }
`;

const BtnApply = styled(Button)`
  transition: background-color 0.5s;

  :hover {
    background: #31a608;
  }
`;

export default Diagnosis;
