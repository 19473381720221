import styled from "styled-components";

const SectionDesc = styled.p`
  margin-top: 40px;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #222;

  @media screen and (max-width: 600px) {
    margin-top: 20px;
    font-size: 12px;
    line-height: 20px;
  }
`;

export default SectionDesc;
