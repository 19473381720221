import styled, { keyframes } from "styled-components";

import SectionLabel from "components/atoms/section/Label";
import SectionTitle from "components/atoms/section/Title";
import SectionDesc from "components/atoms/section/Desc";

import useBackgroundAnimation from "hooks/useBackgroundAnimation";

import speechImg1 from "assets/images/why/speech1.png";
import speechImg2 from "assets/images/why/speech2.png";

const backgroundColor = { defaultColor: "#111", chageColor: "#fff" };

const Why = () => {
  const { sectionRef, contentRef } = useBackgroundAnimation(backgroundColor);

  return (
    <Section ref={sectionRef}>
      <Inner ref={contentRef}>
        <SectionLabel>Why</SectionLabel>
        <SectionTitle>
          UX 리서치 <br />한 번 이상 해보셨나요?
        </SectionTitle>
        <SectionDesc>
          UX 리서치를 해본 서비스와 안 해본 서비스는 <br />
          성장 속도에 차이가 날 수밖에 없습니다
        </SectionDesc>
        <SpeechTop>
          <img src={speechImg1} alt="" />
          <img src={speechImg1} alt="" />
        </SpeechTop>
        <SpeechBottom>
          <img src={speechImg2} alt="" />
          <img src={speechImg2} alt="" />
        </SpeechBottom>
      </Inner>
    </Section>
  );
};

const Section = styled.section`
  position: relative;
  padding: 200px 0 140px 0;
  text-align: center;
  overflow: hidden;
  width: 100%;

  background-color: #111;
  transition: background-color 1.5s ease;

  @media screen and (max-width: 600px) {
    padding: 80px 0 60px 0;
  }
`;

const Inner = styled.div`
  opacity: 0;
  transition: opacity 2s ease;
`;

const SpeechWrap = styled.div`
  display: flex;

  img {
    max-width: none;
  }

  & img + img {
    margin-left: 140px;
  }

  @media screen and (max-width: 600px) {
    img {
      height: 84px;
    }
    & img + img {
      margin-left: 30px;
    }
  }
`;

const flow = keyframes`
  from {
    transform: translateX(-2160px);
  }
  to {
    transform: translateX(0);
  }
`;

const flowMobile = keyframes`
  from {
    transform: translateX(calc(-731px - 30px));
  }
  to {
    transform: translateX(0);
  }
`;

const SpeechTop = styled(SpeechWrap)`
  position: relative;
  left: 0;
  animation: ${flow} 24s linear infinite;
  margin-top: 100px;

  @media screen and (max-width: 600px) {
    margin-top: 80px;
    animation: ${flowMobile} 20s linear infinite;
  }
`;

const flowReverse = keyframes`
  from {
    transform: translateX(0 );
  }
  to {
    transform: translateX(-2286px);
  }
`;

const flowReverseMobile = keyframes`
  from {
    transform: translateX(0 );
  }
  to {
    transform: translateX(calc(-777px - 30px));
  }
`;
const SpeechBottom = styled(SpeechWrap)`
  position: relative;
  left: 0;
  animation: ${flowReverse} 24s linear infinite;
  margin-top: 40px;

  @media screen and (max-width: 600px) {
    margin-top: 0;
    animation: ${flowReverseMobile} 20s linear infinite;
  }
`;

export default Why;
