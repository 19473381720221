import { useRef, useEffect } from "react";

const useBackgroundAnimation = ({ defaultColor, chageColor }) => {
  const sectionRef = useRef(null);
  const contentRef = useRef(null);

  const handleScroll = () => {
    const { top } = sectionRef.current.getBoundingClientRect();

    if (top > window.innerHeight) {
      sectionRef.current.style.backgroundColor = defaultColor;
      contentRef.current.style.opacity = 0;
    } else {
      sectionRef.current.style.backgroundColor = chageColor;
      contentRef.current.style.opacity = 1;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return { sectionRef, contentRef };
};

export default useBackgroundAnimation;
