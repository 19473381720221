import { useState } from "react";
import styled from "styled-components";

import MobileMenu from "components/organisms/MobileMenu";
import SECTION_WIDTH from "components/atoms/section/Width";
import menus from "datas/menus";

import logo from "assets/images/logo.svg";
import iconMenu from "assets/images/icon_menu.svg";

const Header = () => {
  const [visible, setVisible] = useState(false);

  const handleToggle = () => setVisible(!visible);

  return (
    <Container>
      <Inner>
        <a href="/">
          <Logo src={logo} alt="modefyin" />
        </a>
        <MenuWrapper>
          {menus.map(({ id, menu }) => (
            <MenuItem key={id}>
              <a href={`#${id}`}>{menu}</a>
            </MenuItem>
          ))}
        </MenuWrapper>
        <BtnMenu onClick={handleToggle}>
          <img src={iconMenu} alt="" />
        </BtnMenu>
        {visible && <MobileMenu onToggle={handleToggle} />}
      </Inner>
    </Container>
  );
};

const Container = styled.header`
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
  background: rgba(17, 17, 17, 0.2);
  backdrop-filter: blur(17.5px);
  padding: 0 80px;

  @media screen and (max-width: 800px) {
    padding: 0 24px;
  }
`;

const Inner = styled.div`
  display: flex;
  height: 80px;
  align-items: center;
  max-width: ${SECTION_WIDTH}px;
  margin: 0 auto;

  @media screen and (max-width: 800px) {
    height: 56px;
    justify-content: space-between;
  }
`;

const Logo = styled.img`
  margin-right: 80px;
  vertical-align: middle;
`;

const MenuWrapper = styled.ul`
  display: flex;
  gap: 40px;

  @media screen and (max-width: 1000px) {
    gap: 25px;
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const MenuItem = styled.li`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

const BtnMenu = styled.button`
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export default Header;
