import reportImg1 from "assets/images/report/report_img1.png";
import reportImg2 from "assets/images/report/report_img2.png";
import reportImg3 from "assets/images/report/report_img3.png";
import reportImg4 from "assets/images/report/report_img4.png";

const reports = [
  { id: 1, image: reportImg1 },
  { id: 2, image: reportImg2 },
  { id: 3, image: reportImg3 },
  { id: 4, image: reportImg4 },
];

export default reports;
