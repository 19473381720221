import skillImg1 from "assets/images/skill/skill_img1.png";
import skillImg2 from "assets/images/skill/skill_img2.png";
import skillImg3 from "assets/images/skill/skill_img3.png";

const skills = [
  {
    id: 1,
    title: (
      <>
        고객의 목소리를 분석하여 <br />
        서비스의 개선점을 발견해요
      </>
    ),
    description: (
      <>
        <span className="block">서비스에 적합한 리서치 방식을 통해 </span>
        <span className="block">
          사용자의 니즈를 파악하고 <br />
          시장의 수요에 맞는{" "}
        </span>
        <span className="block">서비스 개선점을 도출합니다</span>
      </>
    ),
    image: skillImg1,
  },
  {
    id: 2,
    title: (
      <>
        맞춤형 리서치 구성을 통해 <br />
        빠르게 피드백을 받아보세요
      </>
    ),
    description: (
      <>
        필요한 리서치만 골라 시간과 비용을 줄이고, <br />
        실현 가능성을 빠르게 검증해 보세요
      </>
    ),
    image: skillImg2,
  },
  {
    id: 3,
    title: (
      <>
        솔루션 제안에서 더 나아가 <br />
        UX/UI 개선까지 한 번에!
      </>
    ),
    description: (
      <>
        서비스 검증을 통해 얻은 솔루션을 바탕으로 <br />
        UX/UI 전문가들과 함께 서비스를 개선해 보세요 <br />
        <span className="addExplanation">
          (모디파인은 UX/UI 전문 서비스 제너레이터 리오랩과 함께합니다)
        </span>
      </>
    ),
    image: skillImg3,
  },
];

export default skills;
