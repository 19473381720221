import styled from "styled-components";

import COLORS from "components/atoms/colors";
import iconPlus from "assets/images/introduce/plus.svg";

const Introduce = ({}) => {
  return (
    <Section id="introduce">
      <Title>
        mo
        <img src={iconPlus} alt="" />
        defy
      </Title>
      <SubTitle>
        모디파인은 ‘<strong>more</strong>’ 의 뜻을 가진{" "}
        <strong>
          <span>mo</span>
        </strong>
        와<br />
        ‘맞서다’라는 뜻을 가진{" "}
        <strong>
          <span>defy</span>
        </strong>
        의 합성어입니다
      </SubTitle>
      <Desc>
        모디파인은 시장의 다양한 문제점에 맞서
        <br />
        가치 있는 해답을 제안하고자 하는
        <br />
        UX 리서치 전문 에이전시입니다
      </Desc>
    </Section>
  );
};

const Section = styled.section`
  padding: 360px 0;
  position: relative;
  text-align: center;
  background: #111;

  @media screen and (max-width: 767px) {
    padding: 150px 0;
  }
`;

const Title = styled.h1`
  font-family: "Gotham";
  font-weight: 700;
  font-size: 60px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #ffffff;

  img {
    margin: 0 20px;
  }

  @media screen and (max-width: 600px) {
    font-size: 32px;
    line-height: 32px;

    img {
      margin: 0 17px;
      width: 14px;
      height: 14px;
    }
  }
`;

const SubTitle = styled.h2`
  margin-top: 80px;
  font-weight: 700;
  font-size: 28px;
  line-height: 48px;
  letter-spacing: -0.01em;
  color: #ffffff;

  strong {
    font-family: "Gotham";
  }

  span {
    color: ${COLORS.main};
  }

  @media screen and (max-width: 600px) {
    margin-top: 36px;
    font-size: 14px;
    line-height: 24px;
  }
`;

const Desc = styled.p`
  margin-top: 52px;
  font-size: 24px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #ffffff;
  opacity: 0.8;

  @media screen and (max-width: 600px) {
    margin-top: 28px;
    font-size: 14px;
    line-height: 24px;
  }
`;

export default Introduce;
