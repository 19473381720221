export const DATAS = [
  {
    title: "Starter",
    subTitle: "(MVP)",
    content: (
      <p>
        Starter 단계는 최대 <span>6주 내에</span> 서비스의{" "}
        <span className="mobile" />
        빠른 검증이 가능한 패키지입니다.
      </p>
    ),
    price: "3,000,000원",
    left: [
      { title: "리쿠르팅 (2명)" },
      { title: "UT 미러룸 이용 가능" },
      {
        title: "테스트 설계 비용",
        content: ["UT or IDI 중 택 1", "TASK 3개 이하"],
      },
      {
        title: "테스트 진행 비용",
        content: [
          "1~2회 진행",
          "설문 (사용성 평가 설문 or 서비스 컨셉 설문 중 택 1)",
        ],
      },
    ],
    right: [
      { title: "Raw Data 제공 (영상 / 사진 / 녹음)" },
      { title: "결과 분석 (정성 분석 / 전문가 평가 / 종합 분석 결과)" },
      { title: "종합 결과 보고서" },
    ],
  },
  {
    title: "Runner",
    subTitle: "(MVP+)",
    content: (
      <p>
        Runner 단계는{" "}
        <span>정성, 정량 데이터를 통해 UXUI 사용성 개선 방향</span>과 빠른
        검증이 가능합니다.
      </p>
    ),
    price: "9,000,000원",
    left: [
      {
        title: (
          <span>
            리쿠르팅<span className="purple">(최대 8명)</span>
          </span>
        ),
      },
      { title: <span className="purple">UT 미러룸 / 회의실 이용 가능</span> },
      {
        title: <span>테스트 설계 비용</span>,
        content: [
          <span className="purple">TASK 4개 이하</span>,
          <span className="purple">UT / FGI,IDI</span>,
        ],
      },
      {
        title: <span>테스트 진행 비용</span>,
        content: [
          <span className="purple">2회 진행</span>,
          <span className="purple">
            설문 (사용성 평가 설문, 서비스 컨셉 설문)
          </span>,
        ],
      },
    ],
    right: [
      {
        title: (
          <span>
            Raw Data 제공 (영상 / 사진 / 녹음 /
            <span className="purple"> 화면 녹화본</span>)
          </span>
        ),
      },
      {
        title: (
          <span>
            결과 분석 (<span className="purple">정성&정량 분석</span> / 전문가
            평가 / 종합 분석 결과 /
            <span className="purple"> UXUI 개선 방향 제안</span>)
          </span>
        ),
      },
      { title: <span>종합 결과 보고서</span> },
    ],
  },
  {
    title: "Steady",
    content: (
      <p>
        Steady 단계는 <span>서비스 상태에 따른 맞춤형 진행</span>으로 세밀한
        분석결과가 가능한 패키지입니다.
      </p>
    ),
    price: "13,000,000원",
    left: [
      {
        title: (
          <span>
            리쿠르팅<span className="purple">(최대 12명)</span>
          </span>
        ),
      },
      { title: <span className="purple">UT 미러룸 / 회의실 이용 가능</span> },
      {
        title: <span>테스트 설계 비용</span>,
        content: [
          <span>TASK 5개 이하 (서비스 상태에 따라 변동 가능)</span>,
          <span>UT / FGI,IDI</span>,
        ],
      },
      {
        title: <span>테스트 진행 비용</span>,
        content: [
          <span>3~4회 진행</span>,
          <span>설문 (사용성 평가 설문, 서비스 컨셉 설문)</span>,
        ],
      },
    ],
    right: [
      {
        title: (
          <span>
            Raw Data 제공 (영상 / 사진 / 녹음 /
            <span className="purple"> 화면 녹화본</span>)
          </span>
        ),
      },
      {
        title: (
          <span>
            결과 분석 (<span className="purple">정성&정량 분석</span> / 전문가
            평가 / 종합 분석 결과 /
            <span className="purple">
              {" "}
              UXUI 개선 방향 제안 / 퍼소나&사용자 여정 지도
            </span>
            )
          </span>
        ),
      },
      { title: <span className="purple">서비스 맞춤형 보고서 제작</span> },
    ],
  },
];
