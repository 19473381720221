import styled from "styled-components";
import backgroundImg from "assets/images/contact/background.png";

const Contact = () => {
  return (
    <Section id="contact">
      <Content>
        <Title>
          더 나은 서비스 <br />
          모디파인과 함께
        </Title>
        <BtnContact
          href="https://ifb2hl1sqfj.typeform.com/to/TTgWFmvE"
          target="_blank"
        >
          리서치 문의하기
        </BtnContact>
      </Content>
      <Layer />
    </Section>
  );
};

const Section = styled.section`
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: url(${backgroundImg});
  background-size: cover;
  color: #fff;
  padding: 200px 0;
  @media screen and (max-width: 600px) {
    padding: 80px 100px;
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 60px;
  line-height: 140%;
  letter-spacing: -0.05em;
  @media screen and (max-width: 600px) {
    font-weight: 900;
    font-size: 24px;
    line-height: 40px;
  }
`;

const Layer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 12.72%,
    rgba(0, 0, 0, 0.1) 143.36%
  );
  transform: rotate(-180deg);
`;

const BtnContact = styled.a`
  margin-top: 60px;
  display: inline-block;
  min-width: 162px;
  padding: 28px 60px;
  background: rgba(255, 255, 255, 0.17);
  border: 2px solid #ffffff;
  backdrop-filter: blur(17.5px);
  border-radius: 62.5px;

  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.05em;

  transition: background-color 0.5s;
  :hover {
    background-color: rgba(255, 255, 255, 0.4);
  }

  @media screen and (max-width: 600px) {
    padding: 16px 32px;
    margin-top: 24px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }
`;

export default Contact;
