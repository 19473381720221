import styled from "styled-components";

import SectionLabel from "components/atoms/section/Label";
import SectionTitle from "components/atoms/section/Title";
import SectionDesc from "components/atoms/section/Desc";

import researchDatas from "datas/researchDatas";

const Data = () => {
  return (
    <Section id="data">
      <SectionLabel>Data</SectionLabel>
      <SectionTitle>리서치 결과 Raw 데이터 자료</SectionTitle>
      <DataTopDesc>
        인터뷰이 정보, 영상·화면 녹화, 음성 녹음 파일, 인터뷰 사진, 설문지 결과
        자료, 설문 수치 분석 파일 등 <br />
        리서치 결과 Raw 데이터 자료들을 받아보실 수 있어요
      </DataTopDesc>

      <List>
        {researchDatas.raws.map(({ id, icon, text }) => (
          <Item key={id}>
            <img src={icon} alt="" />
            <Content>{text}</Content>
          </Item>
        ))}
      </List>

      <SectionTitle>리서치 결과 분석 자료</SectionTitle>
      <SectionDesc>
        서비스의 정성·정량 피드백 분석 자료를 제공하고 <br />
        UX/UI 개선 방향을 제안해 드립니다
      </SectionDesc>

      <List>
        {researchDatas.results.map(({ id, icon, text }) => (
          <Item key={id}>
            <img src={icon} alt="" />
            <Content>{text}</Content>
          </Item>
        ))}
      </List>
    </Section>
  );
};

const Section = styled.section`
  padding: 200px 25px;
  text-align: center;
  background: linear-gradient(
      0deg,
      rgba(72, 243, 12, 0.05),
      rgba(72, 243, 12, 0.05)
    ),
    #ffffff;

  ul {
    &:nth-of-type(1) {
      margin-bottom: 380px;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 80px 25px;

    ul {
      &:nth-of-type(1) {
        margin-bottom: 160px;
      }
    }
  }
`;

const DataTopDesc = styled(SectionDesc)`
  word-break: keep-all;

  @media screen and (max-width: 1040px) {
    br {
      display: none;
    }
  }
  @media screen and (max-width: 600px) {
    max-width: 290px;
    margin: 20px auto 0;
  }
`;

const List = styled.ul`
  max-width: 884px;
  margin: 100px auto 0;
  display: flex;
  flex-wrap: wrap;
  gap: 4.4%;
  justify-content: center;

  @media screen and (max-width: 767px) {
    gap: 8px;
  }
  @media screen and (max-width: 600px) {
    margin-top: 60px;
  }
`;

const Item = styled.li`
  margin-bottom: 5%;
  width: 30.4%;
  min-width: 98px;
  height: 292px;
  background: #000;
  border-radius: 20px;
  padding: 60px 10px 0;
  word-break: keep-all;

  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }
  @media screen and (max-width: 600px) {
    height: 134px;
    padding: 24px 0 0 0;

    img {
      height: 28px;
    }
  }
`;

const Content = styled.p`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  height: 72px;
  justify-content: center;

  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.02em;
  color: #ffffff;

  @media screen and (max-width: 600px) {
    margin-top: 18px;
    height: 40px;
    font-size: 12px;
    line-height: 20px;
  }
`;

export default Data;
