import styled from "styled-components";

import SECTION_WIDTH from "components/atoms/section/Width";
import COLORS from "components/atoms/colors";
import Button from "components/atoms/button";

import background from "assets/images/visual/background.png";
import backgroundMobile from "assets/images/visual/background_mobile.png";
import arrowRight from "assets/images/arrow_right.svg";

const Visual = () => {
  return (
    <Section>
      <Inner>
        <Title>
          유저의 피드백을 통해
          <br />더 빠른 성장을
          <br />
          경험해 보세요
          <span />
        </Title>
        <Desc>
          모디파인은 약속합니다. <br />
          지금보다 더 나아질 거라고
        </Desc>
        <LinkBox>
          <BtnResearch
            href="https://ifb2hl1sqfj.typeform.com/to/TTgWFmvE"
            target="_blank"
          >
            리서치 문의하기
            <img src={arrowRight} alt="" />
          </BtnResearch>
          <BtnDiangosis
            href="https://ifb2hl1sqfj.typeform.com/to/xEiYJKVb"
            target="_blank"
            borderColor={COLORS.main}
            background="none"
            color={COLORS.main}
          >
            간단 진단 신청
          </BtnDiangosis>
        </LinkBox>
      </Inner>
    </Section>
  );
};

const Section = styled.section`
  position: relative;
  height: 1361px;
  background: #000 url(${background}) no-repeat center;
  background-size: cover;
  padding: 272px 80px 0;

  @media screen and (max-width: 767px) {
    padding: 176px 24px 0;
    height: 812px;
  }
  @media screen and (max-width: 440px) {
    background-image: url(${backgroundMobile});
  }
`;

const Inner = styled.div`
  position: relative;
  z-index: 2;
  max-width: ${SECTION_WIDTH}px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 72px;
  line-height: 108px;
  letter-spacing: -0.05em;
  color: #ffffff;

  span {
    margin-left: 10px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: ${COLORS.main};
    border-radius: 100%;
  }

  @media screen and (max-width: 1366px) {
    font-size: 59px;
    line-height: 90px;

    span {
      width: 13px;
      height: 13px;
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 36px;
    line-height: 56px;

    span {
      width: 8px;
      height: 8px;
    }
  }
`;

const Desc = styled.p`
  margin-top: 60px;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #ffffff;

  br {
    display: none;
  }

  @media screen and (max-width: 1366px) {
    margin-top: 48px;
    font-size: 23px;
    line-height: 28px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 36px;
    font-size: 14px;
    line-height: 24px;

    br {
      display: block;
    }
  }
`;

const LinkBox = styled.div`
  padding: 100px 0;

  & a + a {
    margin-left: 24px;
  }

  @media screen and (max-width: 1366px) {
    & a + a {
      margin-left: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    a {
      display: flex;
      width: 161px;
      margin: 0;
      letter-spacing: -0.01em;
      padding: 16px 18px;
    }

    & a + a {
      margin-left: 0;
      margin-top: 20px;
      width: 123px;
    }
  }
`;

const BtnDiangosis = styled(Button)`
  transition: background-color 0.5s, color 0.5s;
  background-color: rgba(255, 255, 255, 0);
  &:hover {
    border: 0;
    background-color: rgba(255, 255, 255, 1);
    color: #000;
  }
`;
const BtnResearch = styled(Button)`
  transition: background-color 0.5s;
  &:hover {
    background-color: #31a608;
  }
`;

export default Visual;
