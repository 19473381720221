import styled from "styled-components";

import SECTION_WIDTH from "components/atoms/section/Width";
import skills from "datas/skills";
import useBackgroundAnimation from "hooks/useBackgroundAnimation";

const backgroundColor = { defaultColor: "#fff", chageColor: "#000" };

const Skill = () => {
  const { sectionRef, contentRef } = useBackgroundAnimation(backgroundColor);

  return (
    <Section id="skill" ref={sectionRef}>
      <Inner ref={contentRef}>
        {skills.map(({ id, title, description, image }) => (
          <Item key={id}>
            <TextBox index={id}>
              <Title>{title}</Title>
              <Desc>{description}</Desc>
            </TextBox>
            <ImgBox index={id}>
              <img src={image} alt="" />
            </ImgBox>
          </Item>
        ))}
      </Inner>
    </Section>
  );
};

const Section = styled.section`
  padding: 0 80px;

  transition: background-color 1.5s ease;
  background-color: #fff;

  @media screen and (max-width: 1023px) {
    padding: 0 40px;
  }
`;

const Inner = styled.div`
  max-width: ${SECTION_WIDTH}px;
  margin: 0 auto;

  opacity: 0;
  transition: opacity 2s ease;

  & > div:nth-child(1) {
    padding: 196px 0 262px 0;
  }
  & > div:nth-child(2) {
    padding: 237px 0;
  }
  & > div:nth-child(3) {
    padding: 166px 0 243px 0;
  }

  @media screen and (max-width: 600px) {
    & > div:nth-child(1) {
      padding: 80px 0 60px 0;
    }
    & > div:nth-child(2) {
      padding: 80px 0 64px 0;
    }
    & > div:nth-child(3) {
      padding: 80px 0;
    }
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const TextBox = styled.div`
  order: ${({ index }) => (index === 2 ? 2 : 1)};
  text-align: ${({ index }) => (index === 2 ? "right" : "left")};

  @media screen and (max-width: 1366px) {
    min-width: 530px;
  }
  @media screen and (max-width: 1023px) {
    min-width: 420px;
  }
  @media screen and (max-width: 767px) {
    min-width: auto;
    text-align: left;
    margin-bottom: ${({ index }) => (index === 2 ? "60px" : "40px")};
  }
`;

const ImgBox = styled.div`
  order: ${({ index }) => (index === 2 ? 1 : 2)};
  text-align: ${({ index }) => (index === 2 ? "left" : "right")};
  justify-content: flex-end;

  @media screen and (max-width: 1366px) {
    flex: 1;
  }
  @media screen and (max-width: 1023px) {
    img {
      max-width: 80%;
    }
  }
  @media screen and (max-width: 767px) {
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    img {
      max-width: 243px;
    }
  }
`;

const Title = styled.h1`
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 40px;
  line-height: 160%;
  letter-spacing: -0.05em;
  color: #ffffff;
  word-break: keep-all;

  @media screen and (max-width: 1023px) {
    margin-bottom: 16px;
    font-size: 30px;
  }
  @media screen and (max-width: 600px) {
    font-size: 22px;
    line-height: 36px;
  }
`;

const Desc = styled.p`
  font-size: 20px;
  line-height: 36px;
  letter-spacing: -0.02em;
  color: #ffffff;
  word-break: keep-all;

  .addExplanation {
    opacity: 0.4;
  }

  @media screen and (max-width: 1023px) {
    font-size: 16px;
    line-height: 30px;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 24px;

    .block {
      display: block;

      br {
        display: none;
      }
    }

    .addExplanation {
      font-size: 10px;
    }
  }
`;

export default Skill;
