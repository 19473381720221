import styled from "styled-components";

const SectionTitle = styled.h1`
  font-weight: 700;
  font-size: 48px;
  line-height: 160%;
  letter-spacing: -0.05em;

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export default SectionTitle;
