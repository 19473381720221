import styled from "styled-components";
import COLORS from "components/atoms/colors";

const Button = styled.a`
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 25px 32px;
  text-align: center;
  letter-spacing: -0.01em;
  border-radius: 60px;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: ${({ color }) => color || "#111"};
  background: ${({ background }) => background || COLORS.main};
  border: ${({ borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : 0};

  img {
    margin-left: 11px;
  }

  @media screen and (max-width: 1366px) {
    padding: 17px 24px;
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 767px) {
    padding: 16px 20px;
    font-size: 14px;
    line-height: 20px;

    img {
      height: 20px;
    }
  }
`;

export default Button;
