const menus = [
  {
    id: "introduce",
    menu: "소개",
  },
  {
    id: "process",
    menu: "프로세스",
  },
  {
    id: "data",
    menu: "결과 자료",
  },
  {
    id: "diagnosis",
    menu: "간단 진단",
  },
  {
    id: "fee",
    menu: "가격 패키지",
  },
  {
    id: "contact",
    menu: "문의하기",
  },
];

export default menus;
